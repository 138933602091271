import React, { Component } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import "./scss/style.scss";
import { isAuthenticate } from "./utils/helper";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const ForgetPassword = React.lazy(() =>
  import("./views/pages/login/ForgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("./views/pages/login/ResetPassword")
);
const PageForbidden = React.lazy(() =>
  import("./views/pages/restrict/PageForbidden")
);
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const NewsViewer = React.lazy(() => import("./views/news/NewsViewer"));

class App extends Component {
  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => {
                if (isAuthenticate()) {
                  return <Redirect to={{ pathname: "/", state: {} }} />;
                }
                return <Login {...props} />;
              }}
            />
            <Route
              exact
              path="/views/:id"
              name="Views"
              render={(props) => <NewsViewer {...props} />}
            />
            <Route
              exact
              path="/views"
              name="Views"
              render={(props) => <NewsViewer {...props} />}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/forgot-password"
              name="Forgot Password Page"
              render={(props) => <ForgetPassword {...props} />}
            />
            <Route
              exact
              path="/reset-password/:id"
              name="Reset Password Page"
              render={(props) => <ResetPassword {...props} />}
            />
            <Route
              exact
              path="/403"
              name="Forbidden"
              render={(props) => <PageForbidden {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => {
                if (!isAuthenticate()) {
                  // Move to login page if bearer token expired
                  return <Redirect to={{ pathname: "./login", state: {} }} />;
                }
                return <TheLayout {...props} />;
              }}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
