function treeHTML(element, object) {
  const nodeList = element.childNodes;
  if (nodeList != null) {
    if (nodeList.length) {
      object["content"] = [];
      for (let i = 0; i < nodeList.length; i++) {
        if (nodeList[i].nodeType === 3) {
          object["content"].push(nodeList[i].nodeValue);
        } else {
          object["content"].push({});
          treeHTML(
            nodeList[i],
            object["content"][object["content"].length - 1]
          );
        }
      }
    }
  }
  if (element.attributes != null) {
    if (element.attributes.length) {
      object["attributes"] = {};
      for (let i = 0; i < element.attributes.length; i++) {
        object["attributes"][element.attributes[i].nodeName] =
          element.attributes[i].nodeValue;
      }
    }
  }
}

export function mapDom(element) {
  const treeObject = {};
  treeHTML(element, treeObject);
  const answer = treeObject.content;
  const answerSend = [];
  answer.forEach((item) => {
    if (item.content.length === 1) {
      if (item.attributes) {
        const child = {
          type: "link",
          text: item.content[0].attributes.src,
        };
        answerSend.push(child);
      } else {
        if (item.content[0].trim() !== "") {
          const child = {
            type: "text",
            text: item.content[0],
          };
          answerSend.push(child);
        }
      }
    } else {
      const image = {
        type: "link",
        text: item.content[0].attributes.src,
      };
      const figcaption = {
        type: "figcaption",
        text: item.content[1].content[0],
      };
      const child = {
        type: "figure",
        content: [image, figcaption],
      };
      answerSend.push(child);
    }
  });
  return answerSend;
}

export function mapObject(data) {
  return data
    .map((item) => {
      if (item.type === "text") {
        return `<p>${item.text}</p>`;
      } else if (item.type === "link") {
        return `<figure className="image"><img src=${item.text}></figure>`;
      } else {
        // return `<figure className="image"><img src=${item.content[0].text}>${
        //   item.content[1] && `<figcaption>${item.content[1].text}</figcaption>`
        // }</figure>`;

        return `<figure className="image"><img src=${item.content[0].text
          }><figcaption>${(item.content[1] && item.content[1].text) || ""
          }</figcaption></figure>`;
      }
    })
    .join("");
}
